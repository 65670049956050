<template>
  <h3 class="title">
    {{ t('components.list.standard.title.filter.head.title') }}
  </h3>
</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style src="./Head.scss" scoped lang="scss"></style>
