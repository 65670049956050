<template>
  <div class="title" :class="{ hideSwitchSmall: hideSwitchSmall }">
    <TextStyler
      ><h1>{{ title }}</h1></TextStyler
    >

    <ListStandardTitleFilter v-if="whlPageType !== WhlPageType.Bookmarks" />

    <ListStandardTitleSwitch
      v-if="allowedListTypes?.length > 1"
      :model-value="listType"
      @click="emit('switch')"
    />
  </div>
</template>

<script lang="ts" setup>
import { WhlPageType } from '../../../../models/WhlPageType';
const whlPageType = useWhlPageType();

defineProps<{
  title: string;
  listType: string;
  allowedListTypes: string[];
  hideSwitchSmall: boolean;
}>();

const emit = defineEmits(['switch']);
</script>

<style src="./Title.scss" scoped lang="scss"></style>
