<template>
  <div v-show="useSearchStore().hasAnyValue()" class="filter">
    <ListStandardTitleFilterHead />
    <ListStandardTitleFilterReset />
    <ListStandardTitleFilterList />
  </div>
</template>

<script lang="ts" setup></script>

<style src="./Filter.scss" scoped lang="scss"></style>
