<template>
  <footer class="footer">
    <button class="button" type="button" @click="handleClick">
      <ButtonGhost :content="{ icon: 'ion:arrow-forward', text: buttonText }" />
    </button>
  </footer>
</template>

<script lang="ts" setup>
import { WhlModuleType } from '../../../models/WhlModuleType';

const { t } = useI18n();
const whlModuleType = useWhlModuleType();
const globalStore = useGlobalStore();

const buttonText = computed(() => {
  switch (toValue(whlModuleType)) {
    case WhlModuleType.Event:
      return t('components.list.footer.button.text.event');
    case WhlModuleType.Poi:
      return t('components.list.footer.button.text.poi');
    case WhlModuleType.Tour:
    default:
      return t('components.list.footer.button.text.tour');
  }
});

const handleClick = () => {
  globalStore.increasePageSize(10);
};
</script>

<style src="./Footer.scss" scoped lang="scss"></style>
