<template>
  <button class="like" :aria-label="label">
    <BaseIcon class="icon" :name="icon" />
  </button>
</template>

<script lang="ts" setup>
const props = defineProps<{
  modelValue: boolean;
}>();

const icon = computed(() =>
  props.modelValue ? 'ion:heart' : 'ion:heart-outline'
);
const label = computed(() =>
  props.modelValue ? 'von Merkliste entfernen' : 'zur Merkliste hinzufügen'
);
</script>

<style src="./Like.scss" scoped lang="scss"></style>
