<template>
  <div class="datelistWrap">
    <span class="info">{{
      t('components.teaser.list.text.datelist.moreDates')
    }}</span>

    <ul class="datelist">
      <li v-for="(item, index) in displayedItems" :key="index" class="item">
        <a class="link" :href="item.link">{{ item.text }}</a>
      </li>
      <li v-if="additionalCount > 0" class="item">
        <button class="button link" @click="showAll = !showAll">
          + {{ additionalCount }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import type { Datelist } from '../../models';
const { t } = useI18n();

const props = defineProps<{
  content: Datelist;
}>();

const showAll = ref(false);

const displayedItems = computed(() => {
  return showAll.value ? props.content.items : props.content.items.slice(0, 2);
});

const additionalCount = computed(() => {
  return props.content.items.length > 2 ? props.content.items.length - 2 : 0;
});
</script>

<style src="./Datelist.scss" scoped lang="scss"></style>
